<template>
  <div class="changePassword">
    <p class="title">修改密码</p>
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      label-position="top"
    >
      <el-form-item label="当前密码" prop="currentPass">
        <el-input
          type="password"
          v-model="ruleForm.currentPass"
          placeholder="请输入当前密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="pass">
        <el-input
          type="password"
          v-model="ruleForm.pass"
          autocomplete="off"
          placeholder="请输入新密码"
        ></el-input>
        <p class="tips"><span>*</span>密码至少6位，包含字母和数字</p>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
          type="password"
          v-model="ruleForm.checkPass"
          autocomplete="off"
          placeholder="请再次输入新密码"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确认修改</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { changePwd } from '@/api/zp'
export default {
  name: 'Center',
  data() {
    var checkCurrentPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("当前密码不能为空！"));
      }
      return callback()
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入密码"));
      } else {
        if(value.length<6 || (!/\d/.test(value) || !/[a-z|A-Z]/.test(value))){
          return callback(new Error("密码至少6位，必须包含字母和数字！"));
        }
        return callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        return callback(new Error("两次输入密码不一致!"));
      } else {
        return callback();
      }
    };
    return {
      ruleForm: {
        pass: "",
        checkPass: "",
        currentPass: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        currentPass: [{ validator: checkCurrentPass, trigger: "blur" }],
      },
    };
  },
  components: {
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          changePwd({
            oldPassword: this.ruleForm.currentPass,
            newPassword: this.ruleForm.pass
          }).then(() => {
            this.$message.success('修改成功')
            this.$refs[formName].resetFields();
            sessionStorage.removeItem("userInfo");
            this.$router.replace('/login')
          })
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.changePassword {
  height: 490px;
  background: #ffffff;
  padding: 24px 16px;
}
.changePassword .title {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  padding-bottom: 16px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 16px;
}
.changePassword /deep/ .el-form-item__label {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin-bottom: 8px;
  padding: 0;
}
.changePassword /deep/ .el-input__inner {
  width: 276px;
  height: 38px;
  border: 1px solid #cccccc;
  border-radius: 0;
}
.changePassword /deep/ .el-form-item {
  margin-bottom: 20px;
  position: relative;
}
.changePassword /deep/ .el-button {
  width: 90px;
  height: 34px;
  background: #0780e6;
  border-radius: 0;
  padding: 0;
  line-height: 34px;
  margin-top: 20px;
}
.changePassword .tips {
  position: absolute;
  top: 0;
  left: 296px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.changePassword .tips span {
  color: #ff2647;
}
</style>
